<template>
  <div class="flex flex-col items-center justify-center bg-white p-4 m-6 border-2 border-slate-200 rounded-xl">
    <p class="p-4">
      {{ $t('general.error_message') }}
      <code>{{ error.statusCode }}</code>
      {{ error }}
    </p>
    <p class="p-4">
      <ElementsButtonsTheButton class="button--primary" element-id="error-btn" type="button" @click="resetError(error)">
        {{ $t('buttons.reset') }}
      </ElementsButtonsTheButton>
    </p>
  </div>
</template>
<script setup>
import { navigateTo } from '#imports';
import useAuthStore from '@/stores/useAuthStore.js';

const authObj = useAuthStore();
const resetError = async (error) => {
  if (authObj?.getUser) {
    await authObj.handleRedirect();
  } else {
    await navigateTo('/');
  }
  error.value = null;
};
defineProps({
  error: {
    type: Object,
    required: true
  }
});
</script>
